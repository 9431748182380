import React from "react";
import { Base } from "../components/Base";
import { Container } from "../components/Container";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import hero_screen from "./landing/hero+screen.png";
import new_request_video from "./landing/new_request.mp4";
import iphone_consult from "./landing/iphone-consult.png";
import ipad_consult_completed from "./landing/ipad-consult-completed.png";
import logo_chu_rennes from "./landing/chu_rennes.png";
import logo_foch from "./landing/hopital_foch.svg";
import logo_cpts_le_havre from "./landing/cpts_le_havre.png";
import logo_hds from "./landing/HCADS-red.png";
import logo_ans from "./landing/ans.png";
import logo_claranet from "./landing/claranet.png";
import logo_ovh from "./landing/ovh.png";
import logo_idomed from "./landing/idomed.png";
import logo_prosante from "./landing/prosante.png";
import logo_sephira from "./landing/sephira.png";
import logo_weda from "./landing/weda.png";
import logo_jm from "./landing/logo_jm.svg";
import logo_lifen from "./landing/logo_lifen.svg";
import google_badge from "./landing/google-play-badge.png";
import apple_badge from "./landing/app-store-badge.png";

// TODO pages CGU, etc.

const SEO = () => (
  <Helmet>
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />

    <meta
      name="description"
      content="Omnidoc encourage la collaboration médicale en facilitant et rémunérant les échanges entre médecins.
                                      Disponible depuis l'entrée de la téléexpertise dans la nomenclature, la plateforme permet aux médecins
                                      d'échanger facilement des avis avec leurs correspondants ou dans le cadre d'organisations territoriales.
                                      Omnidoc assure le respect des conditions réglementaires, décharge les médecins de l'administratif
                                      et permet la facturation de l'acte à l'Assurance Maladie."
    />
    <meta
      name="keywords"
      content="téléexpertise médicale rémunérée télé-expertise teleexpertise tele-expertise expertise acte avis télé-dermatologie télédermatologie télé-cardiologie télécardiologie télémédecine télé-médecine assurance maladie"
    />

    <title>Omnidoc | Faciliter et valoriser la collaboration médicale</title>

    <meta property="og:site_name" content="Omnidoc" />
    <meta property="og:url" content="https://omnidoc.fr" />
    <meta
      property="og:title"
      content="Omnidoc - Faciliter et valoriser la collaboration médicale"
    />
    <meta
      property="og:description"
      content="Solution de téléexpertise à destination des médecins libéraux et des établissements de santé."
    />
    <meta
      property="og:image"
      content="https://omnidoc.fr/images/hero-social.png"
    />
    <meta property="og:type" content="website" />

    <meta name="twitter:card" content="summary" />
    <meta
      name="twitter:title"
      content="Omnidoc - Faciliter et valoriser la collaboration médicale"
    />
    <meta
      name="twitter:description"
      content="Solution de téléexpertise à destination des médecins libéraux et des établissements de santé."
    />
    <meta
      name="twitter:image"
      content="https://omnidoc.fr/images/hero-social.png"
    />
    <meta name="twitter:site" content="@omnidoc_app" />
    <meta name="twitter:creator" content="@omnidoc_app" />
  </Helmet>
);

const Hero = () => (
  <div className="flex text-center md:text-left items-center leading-relaxed">
    <div className="w-full md:w-3/5">
      <h1 className="text-3xl md:text-4xl md:font-bold md:leading-relaxed">
        La plateforme de téléexpertise <br />
        qui facilite et valorise <br />
        la collaboration médicale
      </h1>
      <p className="text-lg font-light my-6">
        Omnidoc vous permet d'échanger simplement des avis médicaux, avec vos
        correspondants ou dans le cadre d'organisations territoriales.
        <span className="hidden md:inline">
          {" "}
          Chaque acte de téléexpertise donne lieu à une rémunération de
          l'Assurance Maladie.
        </span>
      </p>

      <div className="text-lg font-medium mt-8 -ml-4 -mb-4 ">
        <div className="inline-block pl-4 pb-4">
          <a
            className="block shadow transition duration-500 hover:shadow-lg rounded-full border border-action bg-action hover:bg-action-600 text-white px-12 py-2"
            href="https://app.omnidoc.fr/register"
          >
            S'inscrire
          </a>
        </div>
        <div className="inline-block pl-4 pb-4">
          <a
            className="block shadow transition duration-500 hover:shadow-lg rounded-full border border-action bg-white hover:bg-action-100 text-action px-12 py-2"
            href="https://app.omnidoc.fr"
          >
            Se&nbsp;connecter
          </a>
        </div>
      </div>
    </div>

    <div className="hidden md:block md:w-2/5">
      <img
        src={hero_screen}
        alt="Omnidoc, plateforme de télé-expertise sécurisée et rémunérée"
      />
    </div>
  </div>
);

const Features = () => (
  <div className="leading-relaxed">
    <header className="text-center">
      <h2 className="text-3xl font-bold leading-loose">
        La solution <span className="hidden md:inline"> de téléexpertise</span>{" "}
        Omnidoc
      </h2>
      <p className="text-xl">
        Omnidoc permet aux médecins d'échanger facilement des avis sécurisés et
        rémunérés
      </p>
    </header>

    <div className="flex flex-col justify-between md:flex-row-reverse -ml-8 my-16 items-center">
      <div className="w-full md:w-2/5 pl-8">
        <h3 className="text-2xl font-bold">Un parcours de soin fluidifié</h3>
        <p className="my-6">
          En mettant en place un canal de téléexpertise avec vos correspondants,
          vous avez l'opportunité d'améliorer et de fluidifier le parcours de
          soin de vos patients.
        </p>
        <p className="my-6">
          Pour le médecin requis, c'est la possibilité de{" "}
          <span className="font-semibold">
            mieux trier et prioriser les demandes de prise en charge
          </span>
          . Pour le requérant, la téléexpertise{" "}
          <span className="font-semibold">
            facilite l'accès à un avis spécialisé et accélère l'orientation du
            patient.
          </span>{" "}
        </p>
      </div>

      <div className="w-full md:w-1/2 pl-8">
        <video
          autoPlay={true}
          loop={true}
          muted={true}
          playsInline={true}
          className="shadow-lg rounded"
        >
          <source src={new_request_video} type="video/mp4" />
        </video>
      </div>
    </div>

    <hr className="h-px bg-gray-300 w-4/5 mx-auto" />

    <div className="flex flex-col justify-between md:flex-row -ml-8 my-16 items-center">
      <div className="w-full md:w-2/5 pl-8">
        <h3 className="text-2xl font-bold">Un accès simplifié à l'expertise</h3>
        <p className="my-6">
          Besoin d'aide pour l'interprétation d’un ECG, une lésion cutanée, un
          cliché de tympan&nbsp;? Utilisez Omnidoc pour envoyer tout type de
          demandes et de documents médicaux.
        </p>
        <p className="my-6">
          <span className="font-semibold">
            Vous pouvez échanger avec vos correspondants habituels ou solliciter
            les{" "}
            <a className="text-action" href="#section-ets">
              réseaux d'experts
            </a>
          </span>{" "}
          qui se sont constitués sur la plateforme autour d'une pathologie ou
          d'un territoire : SOS ECG, Télé HTA, Téléex Pallia...
        </p>
        <div className="flex w-full items-center max-w-sm my-12 md:mt-8 mx-auto md:-mx-4">
          <a
            className="w-2/5 h-auto mx-4"
            href="https://play.google.com/store/apps/details?id=fr.omnidoc.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={google_badge}
              alt="Télécharger l'application mobile pour Android"
            />
          </a>
          <a
            className="w-2/5 h-auto mx-4"
            href="https://apps.apple.com/fr/app/omnidoc/id1532875565"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={apple_badge}
              alt="Télécharger l'application mobile pour Apple"
            />
          </a>
        </div>
      </div>
      <div className="w-full md:w-1/2 pl-8">
        <img
          src={iphone_consult}
          alt="Consultation et réalisation d'actes de télé-expertise depuis un téléphone mobile ou smartphone"
        />
      </div>
    </div>

    <hr className="h-px bg-gray-300 w-4/5 mx-auto" />

    <div className="flex flex-col justify-between md:flex-row-reverse -ml-8 mt-16 items-center">
      <div className="w-full md:w-2/5 pl-8">
        <h3 className="text-2xl font-bold">
          La collaboration médicale valorisée
        </h3>
        <p className="my-6">
          Les avis échangés sur Omnidoc sont éligibles à une{" "}
          <span className="font-semibold">
            rémunération de l'Assurance Maladie
          </span>
          &nbsp;: 12 à 20 euros pour le médecin qui donne l'avis, 5 à 10 euros
          pour celui qui le demande.
        </p>
        <p className="my-6">
          Omnidoc assure le{" "}
          <span className="font-semibold">
            respect des conditions réglementaires
          </span>{" "}
          (sécurité, traçabilité) et vous décharge de l'administratif afin que
          vous puissiez vous concentrer sur l'essentiel&nbsp;: l'acte médical.
        </p>
      </div>

      <div className="w-full md:w-1/2 pl-8">
        <img
          src={ipad_consult_completed}
          alt="Exemple de télé-expertise sur Omnidoc"
          className="shadow-lg"
        />
      </div>
    </div>
  </div>
);

const Groups = () => {
  const Card = ({ logo, alt, title, text }) => (
    <div className="bg-white h-full border-2 border-gray-200 text-center rounded shadow-lg hover:shadow-xl hover:border-action-400 transition duration-500 p-8">
      <img className="h-24 mx-auto" src={logo} alt={alt} />
      <h5 className="text-xl my-4">{title}</h5>
      <p>{text}</p>
    </div>
  );

  return (
    <div className="leading-relaxed  text-center">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-3xl font-bold">
          Réseaux et organisations territoriales
        </h2>
        <p className="text-lg my-12">
          Vous êtes un centre hospitalier souhaitant valoriser les échanges
          ville&#8209;hôpital ou une organisation de médecins libéraux (CPTS,
          ESS) cherchant à améliorer l'articulation entre premiers et seconds
          recours, nous vous fournissons une solution clé en main vous
          permettant de déployer et coordonner la téléexpertise sur votre
          territoire.
        </p>
      </div>

      <p className="text-lg font-light my-4">
        Quelques exemples de réseaux de téléexpertise développés sur la
        plateforme d’Omnidoc
      </p>

      <div className="-ml-4 -mb-4">
        <div className="flex flex-col mx-auto max-w-sm md:max-w-none md:flex-row">
          <a
            className="md:w-1/3 ml-4 mb-4"
            href="https://omnidoc.fr/chu-rennes"
          >
            <Card
              logo={logo_chu_rennes}
              alt="Réseau de télé-expertise CHU Rennes"
              title="CHU de Rennes"
              text="La plateforme de téléexpertise du CHU de Rennes"
            />
          </a>

          <a className="md:w-1/3 ml-4 mb-4" href="https://omnidoc.fr/sextant76">
            <Card
              logo={logo_cpts_le_havre}
              alt="Réseau de téléexpertise de la CPTS Grand Havre"
              title="CPTS Grand Havre"
              text="Réseau de téléexpertise de la CPTS Grand Havre"
            />
          </a>

          <a
            className="md:w-1/3 ml-4 mb-4"
            href="https://omnidoc.fr/hopital-foch"
          >
            <Card
              logo={logo_foch}
              alt="Solution de télé-expertise l'hôpital de Foch"
              title="Hôpital Foch"
              text="Réseau de téléexpertise à destination des patients Covid-long franciliens"
            />
          </a>
        </div>
      </div>

      <div className="mt-16">
        <a
          className="transition duration-500 text-lg rounded-full shadow border border-action bg-action hover:bg-action-600 hover:shadow-lg text-white font-medium inline-block px-12 py-2 "
          href="https://app.omnidoc.fr/group_info"
        >
          En savoir plus
        </a>
      </div>
    </div>
  );
};

const Pricing = () => {
  const Card = ({ name, price, children }) => (
    <div className="flex-1 border shadow-lg ml-8 mb-8">
      <div>
        <h3 className="bg-gray-100 px-8 py-6 border-b text-lg font-semibold">
          {name}
        </h3>
        <div className="px-8 py-6">
          <h4 className="h3 text-2xl mb-6 font-semibold">{price}</h4>
          {children}
        </div>
      </div>
    </div>
  );

  return (
    <div className="text-center leading-relaxed">
      <header>
        <h2 className="text-3xl font-bold">Découvrez nos tarifs</h2>
      </header>

      <div className="-ml-8 -mb-8">
        <div className="flex flex-col md:flex-row max-w-sm md:max-w-3xl mt-16 mx-auto">
          <Card name="Utilisation individuelle" price="Gratuit">
            <ul>
              <li>Solution complète de téléexpertise</li>
              <li>Intégration Vitale et e-CPS</li>
              <li>Compte-rendu auto-généré</li>
              <li className="hidden md:block">Guide de cotation</li>
            </ul>

            <p className="text-center mt-12 mb-4">
              <a
                className="shadow transition duration-500 text-lg text-action border border-action hover:bg-action-100 rounded py-2 px-4"
                href="https://app.omnidoc.fr/register"
              >
                S'inscrire
              </a>
            </p>
          </Card>

          <Card name="Création de réseau" price="Sur devis">
            <ul>
              <li>Gestion collective des demandes</li>
              <li>Planning de garde</li>
              <li>Questionnaire personnalisable</li>
              <li className="hidden md:block">
                Outils de suivi et de coordination
              </li>
            </ul>

            <p className="text-center mt-12 mb-4">
              <a
                className="shadow transition duration-500 text-lg text-action border border-action hover:bg-action-100 rounded py-2 px-4"
                href="https://app.omnidoc.fr/group_info"
              >
                En savoir plus
              </a>
            </p>
          </Card>
        </div>
      </div>
    </div>
  );
};

const FAQ = () => {
  const itemClass = "pl-12 pt-12 w-full md:w-1/2 xl:w-1/3";
  const titleClass = "text-lg font-medium";

  return (
    <div className="leading-relaxed">
      <header>
        <h2 className="text-3xl font-bold text-center mb-16">
          Les questions les plus fréquentes
        </h2>
      </header>
      <div className="flex flex-wrap -mt-12 -ml-12">
        <div className={itemClass}>
          <h3 className={titleClass}>
            Qui peut pratiquer une téléexpertise&nbsp;?
          </h3>
          <p className="mt-4">
            Tout médecin peut recourir à la téléexpertise, quels que soient sa
            spécialité, son secteur d’exercice et son lieu d’exercice (en ville
            ou en établissement de santé).
          </p>
        </div>

        <div className={itemClass}>
          <h3 className={titleClass}>Pour quels patients&nbsp;?</h3>
          <p className="mt-4">
            Depuis septembre 2021, la téléexpertise rémunérée concerne tous les
            patients.
          </p>
        </div>

        <div className={itemClass}>
          <h3 className={titleClass}>Combien l'acte est-il rémunéré&nbsp;?</h3>
          <p className="mt-4">
            L'acte est est pris en charge par l’Assurance Maladie. Pour une
            téléexpertise de niveau 1, le médecin requérant reçoit 5€, le requis
            12€&nbsp;; pour le niveau 2, ils recoivent 10 et 20€.
          </p>
        </div>

        <div className={itemClass}>
          <h3 className={titleClass}>Comment facturer&nbsp;?</h3>
          <p className="mt-4">
            C'est le médecin requis qui cote l'acte, via son logiciel de
            facturation habituel, en précisant l'identité du requérant et du
            patient. Le requérant est rémunéré à l'année.
          </p>
        </div>

        <div className={itemClass}>
          <h3 className={titleClass}>Omnidoc est-il vraiment gratuit&nbsp;?</h3>
          <p className="mt-4">
            L'inscription et les téléexpertises individuelles sont entièrement
            gratuites. En revanche la création de réseaux, permettant
            l'organisation territoriale de la téléexpertise, est payante.
          </p>
        </div>

        <div className={itemClass}>
          <h3 className={titleClass}>
            Mes données sont-elles en sécurité&nbsp;?
          </h3>
          <p className="mt-4">
            Omnidoc respecte l'ensemble des règles pour le stockage et le
            partage des données de santé, en particulier un hébergement HDS
            (Claranet) et une authentification forte.
          </p>
        </div>
      </div>
      <p className="text-center mt-16">
        <Link
          className="transition duration-500 text-lg rounded-full shadow border border-action bg-action hover:bg-action-600 hover:shadow-lg text-white font-medium inline-block px-12 py-2"
          to="/info"
        >
          Voir toutes les questions
        </Link>
      </p>
    </div>
  );
};

const Logo = ({ src, alt, href }) => (
  <a
    className="w-1/2 md:w-1/6"
    href={href}
    rel="noopener noreferrer"
    target="_blank"
  >
    <img className="block p-4" src={src} alt={alt} />
  </a>
);

const Security = () => {
  return (
    <div className="text-center">
      <header className="mb-8">
        <h2 className="text-3xl font-bold text-center mb-8">
          Vos échanges sont sécurisés
        </h2>
        <p className="text-lg">
          Omnidoc est en conformité avec la certification Hébergement Données de
          Santé et le RGPD.
        </p>
      </header>

      <div className="flex flex-wrap items-center justify-center max-w-sm md:max-w-4xl mx-auto -m-4">
        <Logo
          href="https://www.claranet.fr/certification-hds"
          src={logo_hds}
          alt="hds"
        />
        <Logo
          href="https://esante.gouv.fr/labels-certifications/hebergement-des-donnees-de-sante"
          src={logo_ans}
          alt="ans"
        />
        <Logo
          href="https://www.claranet.fr"
          src={logo_claranet}
          alt="claranet"
        />
        <Logo
          href="https://www.ovh.com/fr/private-cloud/healthcare/"
          src={logo_ovh}
          alt="ovh"
        />
      </div>
    </div>
  );
};

const Partneship = () => {
  return (
    <div className="text-center">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-3xl font-bold">
          Un système ouvert et interopérable
        </h2>
        <p className="text-lg my-8">
          Omnidoc s'interopérabilise avec de nombreux outils et services afin de
          faciliter le quotidien des professionnels de santé.
        </p>
      </div>

      <div className="flex flex-wrap items-center justify-center max-w-sm md:max-w-full mx-auto -m-4">
        <Logo
          href="https://wallet.esw.esante.gouv.fr/"
          src={logo_prosante}
          alt="Pro sante connect"
        />
        <Logo href="https://idomed.fr/" src={logo_idomed} alt="idomed" />
        <Logo href="https://www.sephira.fr/" src={logo_sephira} alt="sephira" />
        <Logo href="https://weda.fr/" src={logo_weda} alt="Weda" />
        <Logo
          href="https://www.jeunesmedecins.fr/"
          src={logo_jm}
          alt="Jeunes Médecins"
        />
        <Logo href="https://www.lifen.fr/" src={logo_lifen} alt="Lifen" />
      </div>
    </div>
  );
};

class LandingPage extends React.Component {
  render() {
    return (
      <Base>
        <SEO />

        <header id="home" className="py-24 bg-gray-100">
          <Container>
            <Hero />
          </Container>
        </header>

        <main>
          <section id="section-features" className="py-32">
            <Container>
              <Features />
            </Container>
          </section>

          <section id="section-ets" className="py-32 bg-gray-100">
            <Container>
              <Groups />
            </Container>
          </section>

          <section id="section-pricing" className="py-32 ">
            <Container>
              <Pricing />
            </Container>
          </section>

          <section id="section-partnership" className="py-16 bg-gray-100">
            <Container>
              <Partneship />
            </Container>
          </section>

          <section id="section-faq" className="py-32">
            <Container>
              <FAQ />
            </Container>
          </section>

          <section id="section-security" className="py-16 bg-gray-100">
            <Container>
              <Security />
            </Container>
          </section>
        </main>
      </Base>
    );
  }
}

export default LandingPage;
